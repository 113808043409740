import axios from "@/axios"

export default{
    getUserOrganization(organization){
        return axios.get('admin/user/organization/'+organization);
    },
    
    store(user){
       return axios.post('admin/user/organization',user);
    },

    update(user,user_id){
        return axios.post('admin/user/organization/'+user_id,user);
    },

    destroy(user){
        return axios.delete('admin/user/organization/'+ user.id);
    },

    loadPhotoUser(user_id){
        return axios.get('admin/user/organization/load_photo/' + user_id);
    },

    getAllRole(organizationId){
        return axios.get('admin/user/organization/get_all_role/'+organizationId,);
    }
    


}