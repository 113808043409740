<template>
<v-container>
  <v-data-table
    :loading="loading_data_table"
    :headers="headers"
    :items="desserts"
    sort-by="corporate_name"
    class="elevation-1"
    :footer-props="{
        itemsPerPageText:'Itens por pagina',
        itemsPerPageAllText:'Todos',
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Usuarios</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-toolbar-title>{{organization.corporate_name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" rounded
               ><v-icon small>mdi-plus</v-icon> novo usuário</v-btn
            >
          </template>
          <v-card>
            <div class="title_body">
                  <span class="text_title text-h6">{{ formTitle }}</span>
                  <span class="btn_close"><v-btn icon dark @click="dialog = false" ><v-icon dark > mdi-close </v-icon></v-btn> </span>
             </div>


            <v-card-text>
              <v-container>
                <v-form class="px-3">
                  <v-row>
                  
                    <v-col>
                      <input  style="display:none" type="file" @change="onFileSelected"  ref="photoInput">
                       
                         <v-avatar style="cursor:pointer" class="" size="100" @click="$refs.photoInput.click()">
                          <img v-if="url != null" :src="url" >
                          <img v-else-if="editPhotoUser != null "  :src="'data:image/png;base64,'+editPhotoUser" >
                          <img v-else src="@/assets/default-avatar.png" >
                          
                        </v-avatar> 
                    </v-col>
                  <v-col>

                     <v-select
                          v-model="editedItem.role_id"
                          dense
                          :items="itemsRoles"
                          label="Perfil"
                           item-text="name"
                          item-value="id"
                          :disabled="selectRoleLoading"
                          :loading="selectRoleLoading"
                        ></v-select>

                  </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                      dense
                        v-model="editedItem.name"
                        label="Nome"
                      ></v-text-field
                    ></v-col>
                     <v-col
                      ><v-text-field
                        dense
                        v-model="editedItem.email"
                        label="Email"
                        type="email"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      ><v-text-field
                        dense
                        v-model="editedItem.telephone"
                        label="Telefone"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field  dense label="Funçao" v-model="editedItem.occupation">
                        </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      ><v-text-field
                        dense
                        v-model="editedItem.password"
                        label="Senha"
                        type="password"
                      ></v-text-field
                    ></v-col>
                    <v-col
                      ><v-text-field
                        type="password"
                        dense
                        v-model="editedItem.password_confirmation"
                        label="Confirmação de Senha"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text class="mx-0 mt-3 mr-1" @click="dialog = false"
                >Fechar</v-btn
              >
              <v-btn class="primary mx-0 mt-3" :loading="btnSaveLoading" :disabled="btnSaveLoading" @click="save">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="550px">
          <v-card>
            <v-card-title class="text-h5"
              >Tem certeza de que deseja excluir este item ?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="" @click="closeDelete">Cancelar</v-btn>
              <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data> Nenhum registro encontrado </template>
  </v-data-table>
  </v-container>
</template>


<script>
import {mapGetters} from 'vuex'
import service from "./service";

export default {
  data: () => ({
    selectRoleLoading : false,
    itemsRoles:[],
    btnSaveLoading:false,
    editPhotoUser:null,
    image:null,
    dialog: false,
    dialogDelete: false,
    organization:{},
    headers: [
      { text: "Nome", align: "start", sortable: false, value: "name" },
      { text: "Email", value: "email" },
      { text: "Telefone", value: "telephone" },
      { text: "", align:"end",value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      organization_id: "",
      name: "",
      occupation: "",
      email: "",
      telephone: "",
      password:"",  
      password_confirmation:"",
      role_id:""
    },
    defaultItem: {
      id: "",
      organization_id: "",
      name: "",
      email: "",
      telephone: "",
      password:"",
      confirm_password:"",
      role_id:"",
    },
  }),

  computed: {
    ...mapGetters('RegisterOrganizationUsers',['getOrganization']),
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar Usuário" : "Editar Usuário";
    },
    url() {
      if (!this.image) return ;
      let url = URL.createObjectURL(this.image)
      return  url
    }
  },

  watch: {
    dialog(val) {
      this.url = null
      this.image= null
      this.editPhotoUser=null
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize()
    this.getAllRole()
    
  },

  methods: {
    initialize() {
      this.organization = this.getOrganization
      
      this.loading_data_table = true 
      service.getUserOrganization(this.organization.id).then((response) => {
        this.desserts = response.data
        this.loading_data_table = false
      });
    },
    inputPhoto(){
      this.$refs.to.click()
    },
    onFileSelected(event){
        this.image = event.target.files[0]
    },

    getAllRole(){
      this.selectRoleLoading = true 
       service.getAllRole(this.organization.id).then((response)=>{
         this.itemsRoles = response.data
       }).catch(()=>{
          this.$toast.error("Não foi possível carregar os perfis");
       }).finally(()=>{
        this.selectRoleLoading = false
       })
    },

    editItem(item) {
      
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editPhotoUser = null
      this.url = null
      service.loadPhotoUser(this.editedItem.id)
      .then((result)=>{
        this.editPhotoUser = result.data.length == 0 ? null: result.data 
      })
      .catch(()=>{
        this.$toast.error("Não foi possível carregar a foto.");
      })
      
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      service.destroy(this.editedItem).then(() => {
        this.desserts.splice(editedIndex, 1);
      });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        //update
        
        let indice = this.editedIndex;
         
        let formData = new FormData()

        formData.append('id',this.editedItem.id)
        formData.append('confirm_password',this.editedItem.confirm_password)
        formData.append('password',this.editedItem.password ||'')
        formData.append('password_confirmation',this.editedItem.password_confirmation )
        formData.append('email',this.editedItem.email )
        formData.append('name',this.editedItem.name )
        formData.append('occupation',this.editedItem.occupation )
        formData.append('telephone',this.editedItem.telephone )
        formData.append('organization_id',this.organization.id )
        
        //se não escolheu perfil não envia
        if(this.editedItem.role_id != null){
          formData.append('role_id',this.editedItem.role_id )
        }
               
        //se não alterou a imagem não envia
        if(this.image!=null){
          formData.append('photo',this.image)
        }
        
        this.btnSaveLoading = true

        service.update(formData,this.editedItem.id).then((response) => {
          Object.assign(this.desserts[indice], response.data);
          this.$toast.success("Salvo com sucesso !");
          this.close();
        })
        .catch(()=>{
          
        })
        .finally(()=>{
          this.btnSaveLoading = false
        })
      } else {
        //store
        this.editedItem.organization_id = this.organizationId;
                       
       
        let formData = new FormData()
        
        formData.append('confirm_password',this.editedItem.confirm_password)
        formData.append('password', this.editedItem.password||'')
        formData.append('password_confirmation',this.editedItem.password_confirmation )
        formData.append('email',this.editedItem.email )
        formData.append('name',this.editedItem.name )
        formData.append('occupation',this.editedItem.occupation )
        formData.append('telephone',this.editedItem.telephone )
        formData.append('photo',this.image)
        formData.append('organization_id',this.organization.id )
        
        //se não escolheu perfil não envia
        if(this.editedItem.role_id != null){
          formData.append('role_id',this.editedItem.role_id )
        }
        

        service.store(formData).then((response) => {
          this.desserts.push(response.data);
          this.$toast.success("Salvo com sucesso !");
          this.close();
        })
        .catch(()=>{
       
        })
        .finally()

      }
      
    },
  },
};
</script>
